import {defineStore} from 'pinia'

export const useDeviceStore = defineStore('deviceStore', {
    state: () => ({
        videoDeviceId: '',
        audioDeviceId: '',
        permissionDenied: false,
        systemRequirements: null as boolean | null,
        audioDevices: [] as MediaDevice[],
        cameraDevices: [] as MediaDevice[],
    }),

    actions: {
        setDevices(audioDevices: MediaDevice[], cameraDevices: MediaDevice[]) {
            this.audioDevices = audioDevices
            this.cameraDevices = cameraDevices
        },
        setVideoDevice(deviceId: string) {
            this.videoDeviceId = deviceId;
        },

        setSystemRequirements(success: boolean) {
            this.systemRequirements = success;
        },

        setAudioDevice(deviceId: string) {
            this.audioDeviceId = deviceId;
        },

        async setDefaultDevices(session: typeof Stream)  {
            let videoDevices: MediaDevice[] = []
            try {
                videoDevices = session.getCameraList()
            } catch (e) {
                this.permissionDenied = true
            }

            if (videoDevices && videoDevices.length > 0) {
                this.videoDeviceId = videoDevices[0].deviceId
            }

            let audioDevices: MediaDevice[] = []
            try {
                audioDevices = session.getMicList()
            } catch (e) {
                this.permissionDenied = true
            }

            if (audioDevices && audioDevices.length > 0) {
                this.audioDeviceId = audioDevices[0].deviceId
            }

            console.info("setDefaultDevices", this.videoDeviceId, this.audioDeviceId)
        },

        async deviceExists(deviceId: string): Promise<boolean> {
            try {
                // Requesting permissions for media devices
                await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

                // Getting the list of media devices
                const devices = await navigator.mediaDevices.enumerateDevices();

                console.log('devices', devices)

                // Checking if the device exists in the list
                return devices.some(device => device.deviceId === deviceId);
            } catch (error) {
                console.error('Error accessing media devices:', error);
                return false;
            }
        }
    },
});